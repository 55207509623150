import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SnackbarComponent } from '@app/components/shared/snackbar/snackbar.component';

import { Stage } from '@app/models/course/workbook/stage/stage';

import { TempDataServiceService } from '@app/services/data-services/temp-data-service.service';
import { GlobalEventManagerService } from '@app/services/global-event-manager/global-event-manager.service';

import * as fromApp from '../../../../store/app.reducers';
import * as CourseActions from '../../course/store/course.actions';
import * as CheckoutActions from '../../checkout/store/checkout.actions';
import { AvionicsService } from '@app/services/data-services/avionics.service';
import { CourseService } from '@app/services/data-services/course.service';
import { Order } from '@app/models/order/order';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';

declare var SFIDWidget: any;

@Component({
    selector: 'app-course-dialog',
    templateUrl: './course-dialog.component.html',
    styleUrls: ['./course-dialog.component.scss']
})

export class CourseDialogComponent implements OnInit, OnDestroy {

    courseContent: Stage[];
    subscription = new Subscription();
    order: Order;
    free_purchase = false;
    isMobileLandscape = false;
    defaultCourseThumb = environment.defaultThumbnailCourse;

    constructor(public dialogRef: MatDialogRef<CourseDialogComponent>,
                public snackBar: MatSnackBar,
                @Inject(MAT_DIALOG_DATA) public course: any,
                private tempService: TempDataServiceService,
                private avionicsService: AvionicsService,
                private store: Store<fromApp.AppState>,
                private courseService: CourseService,
                private router: Router,
                private globalEventManagerService: GlobalEventManagerService) {
    }

    ngOnInit() {
      this.checkIfMobileLandscape();
      if (this.isMobileLandscape) {
        this.dialogRef.updateSize('80vw', '95vh');
      }
      this.courseService.getLearningCatalogWorkbook(this.course.id)
        .then(course => {
          if(course && course.workbook) {
            return course.workbook.stages;
          } else {
            return [];
          }
        })
        .then(stages => {
          this.courseContent = stages.sort((a, b) => a.order - b.order);
        });

        this.globalEventManagerService.topBar.emit(true);
        // console.log('INSIDE DIALOG');
        this.subscription.add(this.store.select('checkout').subscribe((data) => {
            this.order = data.order;
        }));
    }

    select(course: any, section: any, isPreview) {
      if (!isPreview || this.free_purchase) {
        return;
      }
      course.workbook = this.courseContent;
      this.store.dispatch(new CourseActions.SelectCourse(course));
      // this.store.dispatch(new CourseActions.SelectSection(section));

      this.dialogRef.close('hello');

      if (!this.free_purchase) {
        this.store.dispatch(new CourseActions.SetPreview(true));
      }

      this.router.navigate(['/course/' + course.id + '/workbook-editor']);
    }

    addToCart(course: any) {
      this.store.dispatch(new CheckoutActions.AddToCart(course));
      this.confirmMessage();
    }

    goToCart() {
      this.dialogRef.close();
      this.router.navigate(['/shopping-cart']);
    }

    checkCart(course) {
      if (this.order && this.order.order_line_items && this.order.order_line_items.length) {
        return this.order.order_line_items.map(line_item => line_item.product_id ? line_item.product_id : -1).indexOf(course.id) > -1;
      } else {
        return false;
      }
    }

    enrollNow(course) {
      this.courseService.enrollNow(course.id)
        .then(user_course => this.free_purchase = true)
        .catch(error => {
          // console.log(error);
          // if (error === '401 Unauthorized') {
          //   SFIDWidget.login();
          // }
        });
    }

    isLastItemInArray(index, array) {
        return ((array.length - 1) === index);
    }

    confirmMessage() {
        this.snackBar.openFromComponent(SnackbarComponent, {
            duration: 2000,
            data: 'Added course to Cart'
        });
    }

    buyNow(a: any) {

    }

    isLoggedIn() {
        return localStorage.getItem('cirrus-token') != null;
    }

    checkIfMobileLandscape() {
      if ((window.matchMedia(`(min-width: 667px)`).matches && window.matchMedia(`(max-width: 850px)`).matches && window.matchMedia('orientation: landscape'))) {
        console.log('Mobile landscape detected!');
        this.isMobileLandscape = true;
      }
    }

    login() {
      this.dialogRef.close();
      // setTimeout(() => SFIDWidget.login(), 1000);
      // window.location.href = 'https://learning.cirrusapproach.com/users/saml/sign_in';
      // window.location.href = 'https://cirrus-lms-api.stage.herokuapp.com/users/saml/sign_in';
      window.location.href = environment.sign_in;
    }

    ngOnDestroy() {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }

}
