import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { Course } from '../../../models/course/course';

import { CourseDialogComponent } from '../../../ui/user/course-catalog/course-dialog/course-dialog.component';

import * as CheckoutActions from '../../../ui/user/checkout/store/checkout.actions';
import * as fromApp from '../../../store/app.reducers';
import {Order} from '@app/models/order/order';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnDestroy, OnInit {
  showCart = false;
  hover = false;
  cartItems = [];
  dialogRef: any;
  model = {
    positionX: 0,
    positionY: 0
  };
  subscription = new Subscription();
  order: Order = new Order();
  total = 0.00;

  constructor(public dialog: MatDialog,
              private store$: Store<fromApp.AppState>,
              private router: Router) { }

  ngOnInit() {
    this.subscription.add(this.store$.select('checkout').subscribe(state => {
      this.order = state.order;
      this.total = 0;
      state.order.order_line_items.forEach(lineItem => {
        if (lineItem.product && lineItem.product.list_price) {
          this.total += parseFloat(lineItem.product.list_price.toString());
        }
      });
    }));
  }

  toggle(el?: any) {
    if (this.showCart === false) {
      this.open(el);
    } else {
      this.close();
    }
  }

  open(el?: any) {
    if (el) {
      this.showCart = !this.showCart;
      this.model.positionX = el._elementRef.nativeElement.offsetLeft - 325;
      this.model.positionY = el._elementRef.nativeElement.offsetTop + el._elementRef.nativeElement.scrollTop + 50;
    }
  }

  close() {
    setTimeout(() => {
      if (this.hover === false) {
        this.showCart = false;
      }
    }, 150);
  }

  goToCart() {
    this.close();
    this.router.navigate(['/shopping-cart']);
  }

  removeItem(item) {
    this.store$.dispatch(new CheckoutActions.RemoveFromCart(item));
  }

  openCourseDialog(course: Course): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.dialogRef = this.dialog.open(CourseDialogComponent, {
      width: '600px',
      height: '700px',
      data: course
    });
  }

  checkLocation() {
    return ['/shopping-cart', '/checkout', '/recent-purchase'].indexOf(this.router.url) === -1;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
