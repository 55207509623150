import { Component, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-ios-login',
  templateUrl: './ios-login.component.html',
  styleUrls: ['./ios-login.component.scss']
})

export class IosLoginComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.location.href = environment.sign_in;
    // window.location.href = 'https://learning.cirrusapproach.com/users/saml/sign_in';
  }

}
