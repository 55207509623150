<div class="editor-wrapper">
  <div class="course-wrapper-alt course-padding-alt top-padding">
    <div class="max-width-course top-padding">
      <div
        fxFlex="100"
        fxFlex.sm="100"
        fxFlex.xs="100"
        fxLayout="column"
        fxLayoutAlign="start start"
        class="red full-width"
      >
        <p class="name bold-txt">Order #: {{ order?.id }}</p>
        <div class="blue full-width" fxLayout="column">
          <div fxLayout="column" class="full-width move-up">
            <div class="full-width">
              <span class="bold-txt">Order Status:</span>
              {{ orderStatusMap[orderStatus[order?.order_status]] }}
            </div>
            <div class="full-width">
              <span class="bold-txt">Created Date: </span
              >{{ order?.created_at | date : 'MM/dd/yyyy' }}
            </div>
            <div class="full-width">
              <span class="bold-txt">Completed Date: </span
              >{{ order?.completed_date | date : 'MM/dd/yyyy' }}
            </div>
            <div class="full-width">
              <span class="bold-txt">Email:</span> {{ order?.user?.email }}
            </div>
          </div>
        </div>
        <div class="full-width move-left">
          <p class="name">Order Bill To</p>
          <table>
            <tr id="receipt-info">
              <th class="bold-txt">First Name</th>
              <th class="bold-txt">Last Name</th>
              <th class="bold-txt">Postal Zip</th>
              <th class="bold-txt">Created</th>
            </tr>
            <tr>
              <td>{{ order?.order_bill_to?.bt_first_name }}</td>
              <td>{{ order?.order_bill_to?.bt_last_name }}</td>
              <td>{{ order?.order_bill_to?.bt_postal_zip }}</td>
              <td>
                {{ order?.order_bill_to?.created_at | date : 'MM/dd/yyyy' }}
              </td>
            </tr>
          </table>
        </div>
        <div class="full-width move-left">
          <p class="name">Order Line Items</p>
          <table>
            <tr id="receipt-info">
              <th class="bold-txt">Item</th>
              <th class="bold-txt">Quantity</th>
              <th class="bold-txt">Price</th>
              <th class="bold-txt">Tax</th>
              <th class="bold-txt">Total</th>
            </tr>
            <tr *ngFor="let item of order.order_line_items">
              <td>{{ item?.product_title }}</td>
              <td>{{ item?.line_item_quantity }}</td>
              <td>{{ item?.line_item_unit_price | currency : 'USD' }}</td>
              <td>{{ item?.line_item_tax | currency : 'USD' }}</td>
              <td>
                {{
                  getTotal(
                    item.line_item_unit_price,
                    item.line_item_tax,
                    item.line_item_quantity
                  ) | currency : 'USD'
                }}
              </td>
            </tr>
          </table>
        </div>
        <div class="full-width move-right">
          <p class="name">Transaction History</p>
          <table>
            <tr id="receipt-info">
              <th class="bold-txt">Transaction ID</th>
              <th class="bold-txt">Transaction Timestamp</th>
              <th class="bold-txt">Transaction Type</th>
              <th class="bold-txt">Payment Type</th>
              <th class="bold-txt">Auth Result</th>
              <th class="bold-txt">Auth Code</th>
            </tr>
            <tr *ngFor="let payment of order.payments">
              <td>{{ payment?.transaction_id }}</td>
              <td>
                {{ payment?.transaction_timestamp | date : 'MM/dd/yyyy' }}
              </td>
              <td>
                {{
                  transactionTypeMap[transactionType[payment.transaction_type]]
                }}
              </td>
              <td>{{ paymentTypeMap[paymentType[payment.payment_type]] }}</td>
              <td>{{ payment?.auth_result }}</td>
              <td>{{ payment?.auth_code }}</td>
            </tr>
          </table>
        </div>
      </div>
      <!--<div class="full-width move-left">
        <p *ngIf="order?.order_status == 'completed'" class="name"><button mat-raised-button [disabled]='isDisabled' [color]="'primary'" (click)='refundOrder()' >Refund Order</button></p>
      </div>-->
    </div>
    <div class="institution-container">
      <div class="image-container">
        <img class="logo" src="assets/images/cirrus-logo-black.png" />
      </div>
      <div class="institution-text">
        ©{{ currentYear }} CIRRUS DESIGN CORPORATION D/B/A CIRRUS AIRCRAFT. ALL
        RIGHTS RESERVED
      </div>
    </div>
  </div>
</div>
