import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

interface ScormResponse {
  initialFile: URL;
}

@Injectable()
export class MediaServerService {
  domain = environment.media_server_domain;

  constructor(private http: HttpClient) {
      this.http = http;
  }

  scormStartingURL(unitName: String): Promise<URL> {
    const url = `${this.domain}/scorm/${unitName}`;
    return this.http.get<ScormResponse>(url).toPromise().then((response) => {
      console.log(response);
      return response.initialFile;
    });
  }
}
