import {Injectable} from '@angular/core';
import {HttpService} from '@app/services/http-service.service';
import {Order} from '@app/models/order/order';
import {OrderLineItem} from '@app/models/order/order-line-item';
import {OrderBillTo} from '@app/models/order/order-bill-to';
import {Payment} from '@app/models/order/payment';

@Injectable()
export class OrderService {
  orderUrl = 'orders';

  constructor(private http: HttpService) {
  }

  getAllOrders(): Promise<Order[]> {
    return this.http.get<Order[]>(this.orderUrl)
      .toPromise()
      .catch(OrderService.handleError);
  }

  getPurchaseHistory(): Promise<Order[]> {
    const url = `${this.orderUrl}/purchase-history`;
    return this.http.get<Order[]>(url)
      .toPromise()
      .catch(OrderService.handleError);
  }

  getOrdersByUser(id: number): Promise<Order[]> {
    const url = `${this.orderUrl}/user-orders/${id}`;
    return this.http.get<Order[]>(url)
      .toPromise()
      .catch(OrderService.handleError);
  }

  getOrder(id: number): Promise<Order> {
    const url = `${this.orderUrl}/${id}`;
    return this.http.get<Order>(url)
      .toPromise()
      .catch(OrderService.handleError);
  }

  getRecentPurchase(id: number): Promise<any> {
    const url = `${this.orderUrl}/recent-purchase/${id}`;
    return this.http.get<any>(url)
      .toPromise()
      .catch(OrderService.handleError);
  }

  purchase(items: OrderLineItem[]): Promise<Order[]> {
    return this.http.post<Order[]>(this.orderUrl, JSON.stringify({courses: items}))
      .toPromise()
      .catch(OrderService.handleError);
  }

  purchaseOrder(order: Order, order_bill_to: OrderBillTo, payment: Payment): Promise<any> {
    const url = `${this.orderUrl}/purchase/${order.id}`;
    return this.http.post<any>(url, JSON.stringify({order, order_bill_to, payment}))
      .toPromise()
      .catch(OrderService.handleError);
  }

  getUserCart(): Promise<Order> {
    const url = `${this.orderUrl}/my-orders`;
    return this.http.get<Order>(url)
      .toPromise()
      .catch(OrderService.handleError);
  }

  updateUserCart(order: Order): Promise<Order> {
    const url = `${this.orderUrl}/update-cart`;
    return this.http.post<Order>(url, JSON.stringify({order: order}))
      .toPromise()
      .catch(OrderService.handleError);
  }

  updateOrder(order: Order): Promise<Order> {
    const url = `${this.orderUrl}/user-order/${order.id}`;
    return this.http.put<Order>(url, JSON.stringify(order))
      .toPromise()
      .catch(OrderService.handleError);
  }
  refundOrder(order: Order): Promise<any> {
    const url = `${this.orderUrl}/refund-order/${order.id}`;
    return this.http.put<any>(url, JSON.stringify(order))
      .toPromise()
      .catch(OrderService.handleError);
  }

  private static handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
