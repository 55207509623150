<div class="cart-wrapper"
     (mouseenter)="hover = true"
     (mouseleave)="hover = false; close()"
     *ngIf="showCart"
     [ngStyle]="{'top': model.positionY+'px', 'left': model.positionX+'px'}">
  <ul class="full-width cart-list" *ngIf="order?.order_line_items?.length">
    <li class="list-item" (mouseenter)="hover = item" (mouseleave)="hover = false" *ngFor="let item of order.order_line_items">
      <!--(click)="openCourseDialog(item)"-->
      <div class="item-container full-width"
           fxFlexAlign="space-between"
           fxLayout>
        <div class="item-image-container">
          <img *ngIf="!item?.product?.image" src="assets/images/img3.jpeg" />
          <img *ngIf="item?.product?.image" src="{{item?.product?.image}}" />
        </div>
        <div class="course-info-wrapper" fxLayout="column">
            <div fxLayout="row">
              <p class="class-title">{{item?.product?.title}}</p>
              <button (click)="removeItem(item)" mat-button class="remove-btn"><mat-icon>close</mat-icon></button>
            </div>
            <div fxLayout="column">
              <p class="course-author">By {{item?.product?.created_by}}</p>
              <p class="course-price">{{item?.product?.list_price | currency}}</p>
            </div>
        </div>
        <!--<button *ngIf="hover === item" (click)="removeItem(item)" mat-button class="remove-btn"><mat-icon>close</mat-icon></button>-->
      </div>
    </li>
  </ul>
  <div class="full-width cart-footer" *ngIf="order.order_line_items && order.order_line_items.length">
    <div class="total-wrapper" fxLayout="row">
      <span class="total-title bold-txt">Total: </span>
      <span class="total bold-txt">{{total | currency:'USD'}}</span>
      <button mat-button  class="review-cart-btn" (click)="goToCart()" *ngIf="checkLocation()">Go To Cart</button>
    </div>
  </div>
  <div class="full-width empty-footer" *ngIf="!order.order_line_items || !order.order_line_items.length">
    Your cart is empty.
    <a [routerLink]="['/learning-catalog']" class="shop-courses">
      Shop for Courses
    </a>
  </div>
</div>
