<mat-dialog-content class="popout-wrapper course-shopping-info" id="course-main-dialog">
    <div class="main-content">
      <div class="full-width">
          <button mat-icon-button mat-dialog-close>
              <mat-icon class="close-icon">close</mat-icon>
          </button>
      </div>

      <!--Top Section of Dialog-->
      <div class="course-info-wrapper">
        <div
          fxLayout.xs="column"
          fxLayout.gt-xs="row"
          fxLayoutGap.xs="15px"
        >
          <div 
            fxFlex="100"
            fxFlex.gt-xs="35"
            fxLayout="column"
            fxLayoutAlign="start"
            class="course-img"
          >
              <img
                [src]="
                  course?.thumbnail_image_url
                    ? course?.thumbnail_image_url
                    : defaultCourseThumb
                "
              />
          </div>
          <div fxFlex="100"
                fxFlex.xs="100"
                fxLayout="column"
                fxLayoutAlign="space-between"
                class="course-img">
              <p class='dialog-course-header'>{{course?.title}}</p>

              <div class='pricerow-align' fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between end">
                <p class='course-price'>Price:</p>
                <p class='dollar-amount course-price'>{{ (course?.list_price > 0 ? course.list_price : 0) | currency }}</p>
                <span fxFlex></span>
                <button class="add-to-cart-button" *ngIf="!isLoggedIn() && !(course?.list_price > 0)" (click)="login()">Enroll</button>
                <button class="add-to-cart-button" *ngIf="!(course?.list_price > 0) && !free_purchase && isLoggedIn()" (click)="enrollNow(course)">Enroll Now</button>
                <button class="view-cart" *ngIf="free_purchase" [routerLink]="['my-courses']" mat-dialog-close>Go to My Courses</button>
                <button class="add-to-cart-button" *ngIf="!checkCart(course) && course?.list_price > 0;"
                          (click)="addToCart(course)"><i class="material-icons shopping-cart-icon">shopping_cart</i>Add to Cart
                </button>
                <button *ngIf="checkCart(course)" class="view-cart" (click)="goToCart()">View Cart</button>
              </div>
          </div>
        </div>
      </div>
    </div>

    <!--Bottom Section of Dialog-->
    <div class="course-content">
        <h6 class='description-header'>Description</h6>
        <p class='description-text-body' [innerHTML]="course?.overview">{{course?.overview}}</p>
        <!--<p class='description-text-body'>{{course?.overview}}</p>-->
      <hr>
        <mat-accordion id="dialog-accordion">
            <mat-expansion-panel *ngFor="let stage of courseContent; let f = first;"
                                 [expanded]="f">
                <mat-expansion-panel-header>
                    <div class="full-width inline-items inline-wrapper">
                        <h6 class="header-row">{{stage?.title}}</h6>
                    </div>
                </mat-expansion-panel-header>
                <mat-list>
                  <a *ngFor="let lesson of stage?.lessons;"
                     (click)="select(course, 'stage' + stage.order + 'lesson' + lesson.order, lesson.is_preview)">
                    <mat-list-item>
                      <div class="lesson-nav-title clickable-link">{{lesson?.title}}</div>
                      <span class="preview-badge" *ngIf="lesson?.is_preview === true">Preview</span>
                    </mat-list-item>
                  </a>
                </mat-list>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</mat-dialog-content>
