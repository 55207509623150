import {Injectable} from '@angular/core';
import * as FullStory from '@fullstory/browser';

@Injectable({
  providedIn: 'root'
})
export class FullstoryService {
  constructor() {}

  init(fullstoryOrgId) {
    FullStory.init({orgId: fullstoryOrgId as string});
  }

  event(eventName: string, eventProperties: any) {
    FullStory.event(eventName, eventProperties);
  }

  identify(cirrusUser) {
    FullStory.identify(cirrusUser.id.toString, {
      role: cirrusUser?.role
    });
  }
}
